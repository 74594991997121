<template>
  <el-dialog
    :visible.sync="showAgreeDialog"
    custom-class="my-dialog"
    top="180px"
    width="1200px"
    :show-close="false"
    :before-close="handleClose"
  >
    <div class="title">注册协议</div>
    <div class="line-1px"></div>
    <div class="content-wrap">
      <p>一、总则</p>
      <p>
        1.
        注册用户同意本协议的全部条款并完成注册程序，才能成为本系统的正式用户。请您仔细阅读本协议，您点击“同意”按钮后即表示完全接受本协议项下的全部条款。
      </p>
      <p>
        2.
        用户一旦注册，即视为用户确认自己具有享受本站服务的权利能力和行为能力，能够独立承担法律责任。
      </p>
      <p style="margin-bottom:20px;">
        3.
        在注册和使用本系统时，用户应了解相关法律法规，确知享有的权利和义务；应保证注册时提交的信息合法、真实、准确，并取得相应授权。
      </p>
      <p>
        二、用户
      </p>
      <p>
        4.注册用户向本系统提供的注册资料应当是真实、准确、完整、合法有效的，用户注册资料如有变动的，应及时更新。如用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果。
      </p>
      <p>
        5.
        用户注册成功后，将产生用户名和密码等账户信息。用户可以根据本系统规定变更密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知我们。
      </p>
      <p>
        6.
        用户应当对以其用户名登录进行的所有活动和事件负法律责任。用户不得将在本系统注册获得的账户转让、出售或出借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
      </p>
      <p style="margin-bottom:20px;">
        7.
        用户同意，本系统拥有通过电子邮件、短信电话等形式，向注册用户和相关申请人发送和告知相关审核信息与结果的权利。该通知于发送之日视为已送达收件人。
      </p>
      <p>三、服务</p>
      <p>8. 本系统服务的具体内容由中国科普网根据实际情况提供并逐步完善。</p>
      <p>
        9.
        本系统仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。
      </p>
      <p>
        10.
        本系统需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，协会无需为此承担任何责任。
      </p>
      <p style="margin-bottom:20px;">
        11.
        如发生下列任何一种情形，本系统有权随时中断或终止向用户提供本协议项下的服务而无需对用户或任何第三方承担任何责任：用户提供的个人资料不真实；用户违反本协议中规定的使用规则；用户名存在违反法律法规或国家政策要求，或侵犯任何第三方合法权益的情形。
      </p>
      <p>四、义务</p>
      <p>
        12.
        用户在使用本系统获取服务时，应当遵守以下义务：遵守中国有关的法律和法规；遵守所有与注册和服务有关的协议、规定和程序；不得为任何非法目的而使用网络服务系统；不得以任何形式在使用本系统过程中侵犯协会及第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；不得利用本系统进行任何可能对互联网正常运转造成不利影响的行为；不得利用本系统提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。
        本系统保有删除各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
      </p>
      <p style="margin-bottom:20px;">
        13.
        若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户账号、终止服务、追究法律责任等措施。
      </p>
      <p>五、隐私保护</p>
      <p>
        14.
        用户在使用本系统进行网上注册并享有服务的过程中，涉及用户真实姓名、通信地址、联系电话、电子邮箱等隐私信息的，本系统将严格予以保密。除非属于下述情形，本系统不会向外界披露用户隐私信息：事先获得用户的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护协会的合法权益。
      </p>
      <p style="margin-bottom:20px;">
        15.
        协会可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与协会同等的保护用户隐私的责任，则协会有权将用户的注册资料等提供给该第三方。
      </p>
      <p>六、知识产权</p>
      <p>
        16.
        本系统提供的网络服务中包含的任何文本、图片、图形、音频和视频资料均受版权、商标和其它财产所有权法律的保护。
      </p>
      <p style="margin-bottom:20px;">
        17.
        除法律另有强制性规定外，未经相关权利人同意，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容；否则，本系统所有者有权追究其法律责任。
      </p>
      <p>七、协议修改</p>
      <p>
        18.
        根据国家法律法规变化及系统运行需要，本系统有权对本协议条款不时地进行修改。一旦本协议的内容发生变动，将会直接在本系统上公布修改之后的协议内容，该公布行为视为已经通知用户修改内容。
      </p>
      <p style="margin-bottom:20px;">
        19.
        如果不同意对本协议相关条款所做的修改，用户有权停止使用服务。如果用户继续使用服务，则视为用户接受本协议修改内容。
      </p>
      <p>八、特别提示</p>
      <p>
        20.
        点击“同意”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
      </p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'WorkspaceJsonAgreeDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAgreeDialog: false, // 同意注册弹窗
    }
  },
  watch: {
    show(newVal) {
      this.showAgreeDialog = newVal
    },
  },
  mounted() {},

  methods: {
    handleClose() {
      this.showAgreeDialog = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .my-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    min-height: 400px;
    height: calc(100vh - 300px);
    overflow: auto;
    padding: 41px 30px 71px;
    .title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .line-1px {
      margin: 20px 0px;
      height: 1px;
      width: 100%;
      background-color: #cccccc;
    }
    .content-wrap {
      color: #666;
    }
  }
}
</style>
