<template>
  <div class="register-container" v-title data-title="注册账号">
    <!-- 头部欢迎信息 -->
    <HeaderWelcome></HeaderWelcome>
    <div class="top-system">
      <div class="info-wrap">
        <span class="name">武汉市前期物业管理电子招投标系统</span>
        <div class="line-1px"></div>
        <span class="register">注册账号</span>
      </div>
    </div>
    <div class="content-wrap">
      <div class="info-wrap">
        <div class="title">招标代理注册</div>
        <RegisterForm></RegisterForm>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import HeaderWelcome from '@/components/common/header/vue/header-welcome.vue'
import RegisterForm from './vue/registerForm.vue'

export default {
  name: 'Forgetpass',
  components: {
    HeaderWelcome,
    RegisterForm,
  },
  data() {
    return {}
  },
  beforeCreate() {
    Vue.prototype.$loading = {
      show: () => {
        this.loading = true
      },
      hide: () => {
        this.loading = false
        this.loadingText = ''
      },
      text: (loadingText) => {
        this.loadingText = loadingText
      },
    }
  },
  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.register-container {
  background: #fff;
  height: 100%;
  .top-system {
    width: 100%;
    min-width: 1200px;
    background: #fff;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 100px;
      display: flex;
      align-items: center;
      .name {
        color: #666666;
        font-size: 30px;
        font-weight: 400;
      }
      .line-1px {
        margin: 0px 30px;
        height: 50px;
        width: 1px;
        background-color: #707070;
      }
      .register {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .content-wrap {
    width: 100%;
    min-width: 1200px;
    // height: calc(100% - 140px);
    background: #f8f9fd;
    padding: 62px 0px 60px;
    // overflow: auto;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 724px;
      overflow: hidden;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .title {
        height: 69px;
        line-height: 69px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #cccccc;
        box-sizing: border-box;
      }
    }
  }
}
</style>
