//校验手机号
export const checkMobilePhoneRule = (mobile) => {
  var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/
  var result = re.test(mobile)
  if (!result) {
    return false
  }
  return true
}

//校验密码复杂度：包含字母、数字、特殊符号三种类型
export const checkPasswordComflex = (password) => {
  // 不区分字母大小写
  const check = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}')
  // 区分字母大小写
  // const check = new RegExp(
  //   '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}'
  // )
  if (check.test(password)) {
    return true
  }
  return false
}
