<template>
  <div class="form-wrap">
    <el-form
      :model="dataForm"
      ref="dataFormRef"
      class="my-Form"
      label-width="150px"
      :rules="rules"
    >
      <el-form-item prop="userName" label="用户名：">
        <el-input
          v-model="dataForm.userName"
          placeholder="用户名"
          autocomplete="new-password"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="输入密码：">
        <el-popover
          popper-class="my-popper-custom"
          placement="right"
          width="300"
          trigger="manual"
          :visible-arrow="false"
          v-model="showPassTip"
        >
          <div class="pass-tip-wrap" :class="['safe' + passwordSafeType]">
            <div class="arrow"></div>
            <div class="deg">
              强度：{{ passwordSafeTxtObj[passwordSafeType] }}
            </div>
            <div class="line-6px"></div>
            <div class="msg">
              请至少输入 8 个字符。需要包含字母、数字、特殊符号三种类型。
            </div>
          </div>
          <el-input
            slot="reference"
            v-model="dataForm.password"
            type="password"
            placeholder="8 - 16位密码，区分大小写"
            autocomplete="new-password"
            :maxlength="16"
            @input="checkPass"
          >
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item prop="confirmPassword" label="确认密码：">
        <el-input
          v-model="dataForm.confirmPassword"
          type="password"
          placeholder="确认密码"
          autocomplete="off"
          :maxlength="16"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="phone" class="phone-wrap" label="手机号：">
        <!-- <span class="phone-area">+86</span> -->
        <el-popover
          popper-class="my-popper"
          placement="right"
          width="200"
          trigger="manual"
          :visible-arrow="false"
          v-model="showMobileTip"
        >
          <div class="pass-tip-wrap">
            <div class="arrow"></div>
            手机号已注册
          </div>
          <el-input
            slot="reference"
            v-model="dataForm.phone"
            placeholder="11位手机号"
            oninput="value = value.replace(/[^\d]/g, '')"
            :maxlength="11"
            autocomplete="off"
          >
            <template slot="prepend">+86</template>
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item prop="code" class="verifyCode-wrap" label="验证码：">
        <el-input
          v-model="dataForm.code"
          placeholder="短信验证码"
          autocomplete="off"
          :maxlength="6"
          oninput="value = value.replace(/[^\d]/g, '')"
        >
        </el-input>
        <el-button
          v-if="!showCodeSecond"
          class="send-btn"
          type="primary"
          @click="handleSendCode"
          >发送验证码</el-button
        >
        <span v-else class="second-time">{{ secondTextVal }}</span>
      </el-form-item>
      <el-form-item prop="socialCode" label="企业信用代码：">
        <el-popover
          popper-class="my-popper"
          placement="right"
          width="200"
          trigger="manual"
          :visible-arrow="false"
          v-model="showVerifyTip"
        >
          <div class="pass-tip-wrap">
            <div class="arrow"></div>
            企业已注册
          </div>
          <el-input
            slot="reference"
            v-model="dataForm.socialCode"
            placeholder="输入企业信用代码"
            :maxlength="18"
            autocomplete="off"
          >
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item prop="agree" class="agree">
        <el-checkbox v-model="dataForm.agree"></el-checkbox>
        <span class="txt" @click="handleOpenAgree">同意注册协议</span>
      </el-form-item>
    </el-form>
    <div class="btn-wrap">
      <el-button
        class="submit-btn"
        type="primary"
        @click="handleSubmitForm('dataFormRef')"
        >确认</el-button
      >
      <el-button class="login-btn" @click="goLoginPage"
        >使用已有账号登录</el-button
      >
    </div>
    <!-- 注册协议 -->
    <AgreeDialog
      :show="showAgreeDialog"
      @close="() => (showAgreeDialog = false)"
    />
  </div>
</template>
<script>
import {
  RegisterSendCode,
  CheckRegisterPhone,
  checkRegisterCreditCodeExist,
  RegisterAddUser,
} from '@/axios/api/main.js'
import { v4 } from 'uuid'
import {
  checkMobilePhoneRule,
  checkPasswordComflex,
} from '@/utils/validateRuleUtil.js'
import AgreeDialog from './agreeDialog.vue'
import JSEncrypt from 'jsencrypt'

export default {
  props: {},
  components: {
    AgreeDialog,
  },
  data() {
    return {
      dataForm: {
        userName: '', // 用户名
        password: '', // 登录密码
        confirmPassword: '', // 确认密码
        phone: '', // 手机号
        code: '', // 验证码
        socialCode: '', // 企业信用代码
        agree: '', // 同意注册协议
      },
      passwordSafeType: null, // 1-不安全，2-有风险，3-安全
      passwordSafeTxtObj: {
        1: '不安全',
        2: '有风险',
        3: '安全',
      },
      rules: {
        userName: [{ required: true, message: '用户名不能为空' }],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('密码不能为空'))
              } else {
                if (value.length < 8) {
                  callback(new Error('密码不能少于8个字符'))
                }
                if (!checkPasswordComflex(value)) {
                  callback(
                    new Error('密码必须包含字母、数字、特殊符号三种类型')
                  )
                }
                if (value === this.dataForm.confirmPassword) {
                  this.$refs['dataFormRef'].clearValidate(['confirmPassword'])
                }
                callback()
              }
            },
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('确认密码不能为空'))
              } else {
                if (value !== this.dataForm.password) {
                  callback(new Error('两次密码不一致'))
                }
                callback()
              }
            },
          },
        ],
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('手机号不能为空'))
              } else {
                if (!checkMobilePhoneRule(value)) {
                  callback(new Error('请输入有效手机号'))
                }
                callback()
              }
            },
          },
        ],
        code: [{ required: true, message: '验证码不能为空' }],
        socialCode: [{ required: true, message: '企业信用代码不能为空' }],
        agree: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请勾选同意注册协议'))
              }
              callback()
            },
          },
        ],
      },
      showPassTip: false, // 校验密码强度标识
      showVerifyTip: false, // 校验企业注册标识
      showMobileTip: false, // 校验手机号标识
      uuId: '',
      showAgreeDialog: false, // 同意注册弹窗
      codeTimer: null,
      showCodeSecond: false, // 显示验证码倒计时
      secondNum: 60,
    }
  },
  computed: {
    secondTextVal() {
      return this.secondNum + 's'
    },
  },
  mounted() {},
  methods: {
    // 校验密码强度，并且提示
    checkPass() {
      if (this.dataForm.password) {
        if (this.dataForm.password.length <= 10) {
          this.passwordSafeType = 1
        } else if (this.dataForm.password.length <= 12) {
          this.passwordSafeType = 2
        } else {
          this.passwordSafeType = 3
        }

        this.showPassTip = true
        // setTimeout(() => {
        //   this.showPassTip = false
        // }, 3000)
        return false
      } else {
        this.showPassTip = false
        return true
      }
    },
    // 校验手机号是否已注册，并且提示
    async checkMobile() {
      const res = await CheckRegisterPhone({ phone: this.dataForm.phone })
      if (!res) {
        this.showMobileTip = true
        // setTimeout(() => {
        //   this.showMobileTip = false
        // }, 3000)
        return false
      } else {
        this.showMobileTip = false
        return true
      }
    },
    // 校验企业是否已注册，并且提示
    async checkCreditCode() {
      const res = await checkRegisterCreditCodeExist({
        creditCode: this.dataForm.socialCode,
      })
      if (!res) {
        this.showVerifyTip = true
        // setTimeout(() => {
        //   this.showVerifyTip = false
        // }, 3000)
        return false
      } else {
        this.showVerifyTip = false
        return true
      }
    },
    async handleSendCode() {
      this.$refs.dataFormRef.validateField('phone', async (error) => {
        if (!error) {
          this.uuId = v4()
          const param = {
            userName: this.dataForm.userName,
            phone: this.dataForm.phone,
            type: '1', // 1通过手机号2通过邮箱
            deviceId: this.uuId,
            smsType: '1',
          }
          await RegisterSendCode(param)
          this.showCodeSecond = true
          this.codeTimer = setInterval(() => {
            if (this.secondNum == 0) {
              clearInterval(this.codeTimer)
              this.showCodeSecond = false
              this.secondNum = 60
            } else {
              this.secondNum--
            }
          }, 1000)
        }
      })
    },
    // 注册
    async handleSubmitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(!this.uuId){
            $this.$Message.warning("请发送验证码");
            return
          }
          const flag1 = await this.checkMobile()
          const flag2 = await this.checkCreditCode()
          if (flag1 && flag2) {
            let encryptor = new JSEncrypt()
            let publicKey =
                'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqPvlpJjq2wOBQnkZ1oiepyX2lKq/LIjuG+C17Fdd+gsYQTUJbVNLgNcBXtymSAgmllpBc1J3U0Ojf07j58olnb7jMTGRlsBsmFEhNY51pv2KfbHjNfnhvI3Fi6eO1bPmfwj4dIPDJP8c9ChFtWfliwVxF8yFryEtMb3HqLa6w5QIDAQAB'
            encryptor.setPublicKey(publicKey)
            const param = {
              userName: this.dataForm.userName,
              password: encryptor.encrypt(this.dataForm.password),
              phone: this.dataForm.phone,
              code: this.dataForm.code,
              deviceId: this.uuId,
              socialCode: this.dataForm.socialCode,
            }
            try {
              await RegisterAddUser(param)
              this.$message.success('注册成功，请登录系统进行信息完善')
              this.$router.push({ path: '/login' })
            }catch (err) {
              console.log(err)
            }
          }
        }
      })
    },
    goLoginPage() {
      this.$router.push({ path: '/login' })
    },
    handleOpenAgree() {
      this.showAgreeDialog = true
    },
  },
  destroyed() {
    clearInterval(this.codeTimer)
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>

<style lang="less" scoped>
.form-wrap {
  width: 510px;
  margin: 0px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep .my-Form {
    .el-form-item {
      height: 42px;
      width: 450px;
      .el-form-item__label {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        margin-bottom: 0px;
      }
      &.phone-wrap {
        .el-form-item__content {
          // margin-left: 200px !important;
          position: relative;
          .el-input-group__prepend {
            color: #333;
            background-color: #fff;
            border: 1px solid #cccccc;
            border-right: none;
            box-sizing: border-box;
            font-size: 16px;
            border-radius: 4px 0px 0px 4px;
          }
          input {
            border-radius: 0px 4px 4px 0px !important;
          }
          // .phone-area {
          //   position: absolute;
          //   top: 0px;
          //   left: -50px;
          //   width: 50px;
          //   height: 42px;
          //   line-height: 42px;
          //   text-align: center;
          //   color: #333;
          //   border: 1px solid #cccccc;
          //   border-right: none;
          //   box-sizing: border-box;
          //   font-size: 16px;
          //   border-radius: 4px 0px 0px 4px;
          // }
        }
      }
      &.verifyCode-wrap {
        width: 330px;
        .el-form-item__content {
          position: relative;
          .send-btn {
            position: absolute;
            height: 42px;
            top: 0px;
            right: -120px;
          }
          .second-time {
            position: absolute;
            top: 0px;
            right: -120px;
            width: 110px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            color: #333;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            font-size: 16px;
          }
        }
      }
      .el-form-item__content {
        input {
          height: 42px;
          font-size: 16px;
          padding-left: 20px;
          &::placeholder {
            color: #ccc;
            font-size: 16px;
          }
        }
        .el-input__prefix {
          left: 20px;
          i {
            font-size: 20px;
          }
        }
        .txt {
          margin-left: 10px;
          color: #4184f4;
          font-size: 16px;
          cursor: pointer;
        }
      }
      &.agree {
        .el-form-item__content {
          .el-form-item__error {
            top: calc(100% - 20px);
          }
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    .submit-btn {
      width: 240px;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
    .login-btn {
      width: 240px;
      height: 50px;
      background-color: #ffffff;
      border-color: #666;
      font-size: 16px;
      font-weight: 400;
      color: #666;
      margin-left: 30px;
    }
  }
}
</style>
<style lang="less">
.my-popper-custom {
  margin-left: 45px !important;
  background-color: #fff;
  color: #666666;
  padding: 14px 20px 20px;
  font-size: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .pass-tip-wrap {
    display: flex;
    flex-direction: column;
    .arrow {
      position: relative;
      width: 0px;
      height: 0px;
      &::after {
        position: absolute;
        display: inline-block;
        top: 48px;
        left: -28px;
        width: 0;
        height: 0px;
        content: '';
        border-style: solid;
        border-width: 8px;
        border-color: #fff #fff transparent transparent;
        transform: rotate(225deg);
        box-shadow: 2px -2px 0px #ebeef5;
      }
    }
    &.safe1 {
      .deg {
        color: #f56c6c;
      }
      .line-6px {
        background-color: #f56c6c;
      }
    }
    &.safe2 {
      .deg {
        color: #e6a23c;
      }
      .line-6px {
        background-color: #e6a23c;
      }
    }
    &.safe3 {
      .deg {
        color: #52c41a;
      }
      .line-6px {
        background-color: #52c41a;
      }
    }
    .deg {
      color: #f56c6c;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .line-6px {
      width: 100%;
      height: 6px;
      background-color: #f56c6c;
      border-radius: 3px;
      margin-bottom: 16px;
    }
  }
  .popper__arrow {
    left: -12px !important;
    border-width: 8px 12px;
    border-right-color: #fff !important;
    &::after {
      border-right-color: #fff !important;
    }
  }
}
.my-popper {
  margin-left: 45px !important;
  background-color: #f9e3e3;
  color: #ff0000;
  padding: 8px 50px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .pass-tip-wrap {
    .arrow {
      position: relative;
      width: 0px;
      height: 0px;
      &::after {
        position: absolute;
        display: inline-block;
        top: 6px;
        left: -58px;
        width: 0;
        height: 0;
        content: '';
        border-style: solid;
        border-width: 8px;
        border-color: #f9e3e3 #f9e3e3 transparent transparent;
        transform: rotate(225deg);
        box-shadow: 2px -2px 0px #ebeef5;
      }
    }
  }
  .popper__arrow {
    left: -12px !important;
    border-width: 8px 12px;
    border-right-color: #f9e3e3 !important;
    &::after {
      border-right-color: #f9e3e3 !important;
    }
  }
}
</style>
